<template>
  <page-layout>
    <template slot="side">
      <h1 class="title is-2 has-text-weight-black">
        <span class="has-text-secondary" v-t="`press.label`"></span><br>
        <span v-t="`contact.lets-talk`"></span>
      </h1>
      <hr>
      <p class="subtitle">
        <i18n path="press.contact-us" tag='span'>
          <template #contact-link>
            <router-link :to="{ name: 'contact' }" v-t="`press.here`"></router-link>
          </template>
        </i18n>
      </p>
      <a
        class="button is-primary is-medium is-fullwidth"
        :href="$config.app.mediaPackUrl"
        v-t="`press.media-pack`"
        download
      ></a>
    </template>

    <template slot="mobile">
      <h1 class="title is-2 has-text-weight-black">
        <span class="has-text-secondary" v-t="`press.label`"></span>
        &nbsp;
        <span v-t="`contact.lets-talk`"></span>
      </h1>
      <p class="subtitle">
        <i18n path="press.contact-us" tag='span'>
          <template #contact-link>
            <router-link :to="{ name: 'contact' }" v-t="`press.here`"></router-link>
          </template>
        </i18n>
      </p>
      <hr>
    </template>

    <template slot="main">
      <div class="columns is-multiline" v-masonry item-selector=".publication-container">
        <div class="column is-4 publication-container"
             v-masonry-tile
             v-for="(publication, key) in publications" :key="key">
          <a class="box press-card" target="_blank" ref="noopener" :href="publication.url">
            <div class="press-card-link">
              <i class="fas fa-external-link-alt has-text-grey-light"></i>
            </div>
            <div class="press-card-logo">
              <img v-lazy="publication.logo" :alt="publication.name">
            </div>
            <div class="press-card-content">
              <p class="has-text-grey">
                "{{ publication.preview }}"
              </p>
            </div>
          </a>
        </div>
      </div>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState({
    publications: state => state.publications
  })
}
</script>
